<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <b-img
            :src="appLogoImage"
            alt="logo"
            style="max-width: 36px;"
          />
          <h2 class="brand-text text-primary ml-1">
            <span v-text="appName" />
          </h2>
        </b-link>

        <b-card-title
          v-if="isTokenStillValid"
          class="mb-1"
        >
          Set a New Password
        </b-card-title>

        <!-- form -->
        <validation-observer
          v-if="isTokenStillValid"
          ref="simpleRules"
          #default="{invalid}"
        >
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  readonly=""
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-form>
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="New Password"
              label-for="new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  id="new-password"
                  v-model="formData.password"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Enter Password"
                  name="new-password"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid || loading === true"
            >
              <span v-if="loading === true">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>
                Reset
              </span>
            </b-button>
          </b-form>
        </validation-observer>

        <div
          v-if="isTokenStillValid === false"
          v-text="tokenErrorMessage"
        />

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSpinner, BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { $themeConfig } from '@themeConfig'
import Account from '@/models/Account'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSpinner,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      required,
      email,
      isTokenStillValid: null,
      tokenErrorMessage: null,
      loading: false,
      formData: {
        email: typeof this.$route.query.email !== 'undefined' ? this.$route.query.email : '',
        password: '',
        accessToken: '',
        tokenId: '',
        type: 'admin',
      },
    }
  },
  mounted() {
    this.formData.accessToken = this.$route.params.accessToken
    this.formData.tokenId = this.$route.params.tokenId
    this.validateToken()
  },
  methods: {
    async validateToken() {
      this.isTokenStillValid = null
      const response = await Account.validateToken({ tokenId: this.formData.tokenId })
      if (response.data.isTokenValid) {
        this.isTokenStillValid = true
      } else {
        this.tokenErrorMessage = response.data.message
        this.isTokenStillValid = false
      }
    },
    async validationForm() {
      const validator = await this.$refs.simpleRules.validate()
      if (validator) {
        try {
          this.loading = true
          const response = await Account.changePassword(this.formData)
          if (response.data.success) {
            this.$swal({
              title: response.data.title,
              text: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(() => {
              this.$router.push({ name: 'login', query: { email: this.formData.email } })
            })
          } else {
            this.$swal({
              title: response.data.title,
              text: response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          this.loading = false
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          }, {
            position: 'top-center',
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
